import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import BlogDetails from "@/components/blog-details";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";

const BlogPostTemplate = ({ pageContext }) => {
  const { post } = pageContext

  return (
    <MenuContextProvider>
    <SearchContextProvider>
      <Layout PageTitle={post.attributes.titulo}>
        <HeaderOne />
        <PageBanner title="Blog Details" name="Blog" />
        <BlogDetails post={post} />
        <Footer />
      </Layout>
    </SearchContextProvider>
  </MenuContextProvider>
  )
}

export default BlogPostTemplate