/** @jsx jsx */
import { Fragment } from 'react'
import DateFormat from '@/components/date-format'
import ReactMarkdown from 'react-markdown'
import { jsx } from '@emotion/react'

import {
  singleBlog,
  blogThumb,
  blogHeadings,
  blogDate,
  blogMetas,
  blogDetails,
} from '../assets/styles/SinglePostCard.styles'

const SinglePostCard = (props) => {
  const { post, url } = props

  return (
    <Fragment>
      <div css={singleBlog}>
        <div css={blogHeadings}>
          <h2>{post?.attributes?.titulo}</h2>
          <p css={blogMetas}>
            <a href='#'>Publicado por {post?.attributes?.autor}</a>
            <p>
              <DateFormat createdDate={post?.attributes?.createdAt} />
            </p>
          </p>
        </div>
        <div css={blogThumb}>
          {post?.attributes?.imagem_destaque && (
            <img
              src={
                url + post?.attributes?.imagem_destaque?.data?.attributes?.url
              }
              alt={post?.attributes?.imagem_destaque?.data?.attributes?.name}
            />
          )}
        </div>
        <div css={blogDetails}>
          <ReactMarkdown>{post?.attributes?.conteudo}</ReactMarkdown>
        </div>
      </div>
    </Fragment>
  )
}

export default SinglePostCard